import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { createContext, useContext, useState, ReactNode } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "..";
import { PropsUserData } from "../utils/interfaces";
// import useIsMobile from "../hooks/useIsMobile";
// Definir un tipo para los datos del contexto
type MyContextData = {
  user: PropsUserData | null;
  setUser: (e: PropsUserData | null) => void;
  refresh: boolean;
  setRefresh: (e: boolean) => void;
  handleLogin: () => Promise<void>;
  handleSignOut: () => Promise<void>;
};

// Crear el contexto
const MyContext = createContext<MyContextData | undefined>(undefined);

// Crear un proveedor del contexto
export const MyContextProvider = ({ children }: { children: ReactNode }) => {
  const auth = getAuth();
  // const isMobile = useIsMobile();
  const userString = localStorage.getItem("user");
  const userLocalStorage: PropsUserData | null = userString
    ? JSON.parse(userString)
    : null;
  const [user, setUser] = useState<PropsUserData | null>(userLocalStorage);
  const [refresh, setRefresh] = useState(false);
  const provider = new GoogleAuthProvider();

  const handleLogin = async () => {
    // if (isMobile) {
    //   window.location.href = "/isMobile";
    // } else {
    try {
      signInWithPopup(auth, provider)
        .then(async (result) => {
          if (result?.user.uid) {
            try {
              let parts = result?.user.displayName?.split(" "); // Esto divide el string en ["Francisco", "Olivero"]
              let firstName = parts && parts[0]; // "Francisco"
              let lastName = parts && parts[1];
              const newUser = {
                firstName: firstName,
                secondName: lastName,
                email: result?.user.email,
                photoProfile: result?.user?.photoURL,
                id: result?.user.uid,
                isActiveAccount: false,
                isTeacher: false,
                timestamp: new Date(),
              };
              setUser(newUser as PropsUserData);
              localStorage.setItem("user", JSON.stringify(newUser));
              const userDoc = doc(db, "Users", result?.user.uid);
              const docSnap = await getDoc(userDoc);

              if (!docSnap.data()) {
                await setDoc(doc(db, "Users", result?.user.uid), newUser);
              }
            } catch (e) {
              console.error("Error adding document: ", e);
            }
          }
        })
        .catch((error) => {
          alert("Intentalo nuevamente");
        });
    } catch (error) {}
    // }
  };

  const handleSignOut = async () => {
    await signOut(auth);
  };

  return (
    <MyContext.Provider
      value={{
        user,
        setUser,
        handleLogin,
        handleSignOut,
        setRefresh,
        refresh,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

// Crear un hook personalizado para acceder al contexto
export const useMyContext = (): MyContextData => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error(
      "useMyContext debe ser usado dentro de un MyContextProvider"
    );
  }
  return context;
};
